import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Card } from "../components/Card"
import Layout from "../components/Layout"
import { H1 } from "../components/Titles"
export default () => (
  <Layout>
    <H1>References</H1>
    <Card>
      <ul>
        <li>
          WLA has developed its own dynamic tracking antennas, using the newest
          soft- and hardware
        </li>
        <li>
          WLA has a proven track record since ten years having performed some
          8,000 flight hours
        </li>
        <li>
          WLA is partner for Public broadcasters such as TVE, TPC Switzerland, …
        </li>
        <li>
          WLA is partner for NEP for all WRC rallies, worldwide since many years
        </li>
        <li>Commonwealth Games 2018 in Australia for NEP Group</li>
        <li>
          World Cycling Road Championships in Bergen, Innsbruck, Doha,
          Yorkshire, …
        </li>
        <li>Nearly hundred Nordic Ski events</li>
        <li>
          Over Hundred running events, triathlons and marathons for Timeline,
          NEP, AMP Visual TV, Broadcast RF, SIT SPORTS, IBS ...
        </li>
        <li>
          We also performed special projects for BBC Stargazing, Sailing events
          such as the start of the Vendee Globe races.
        </li>
        <li>
          During the presidential elections in France, May 2017, 2 aircrafts
          transmitted a total of 15 sources for TF1 and for France Television
          during the evening show.
        </li>
        <li>
          <a
            href="https://www.ampvisualtv.tv/en/our-offers-and-services/267-rf-solutions"
            target="_blank"
          >
            AMP
          </a>
        </li>
        <li>
          <a
            href="https://www.wrc.com/en/video/player/?videoId=1561760"
            target="_blank"
          >
            WRC
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.sbs.com.au/sport/video/winning-moment-stage-18-la-vuelta-2022/788ylk59v"
          >
            Vuelta 2022
          </a>
        </li>
        <li>...</li>
      </ul>
      <p>
        The list is endless and if you have a particular project, please contact
        us.
      </p>
    </Card>
  </Layout>
)
